export const useModules = async () => {
    const currentUser = await useCurrentUser()

    const modules = []

    if (currentUser?.role === 'admin' || currentUser?.role === 'recruiter') {
        modules.push({
            label: "Vagas",
            name: "Vagas",
            description: "Gerenciar vagas e candidatos",
            to: "/job/opened",
            color: "orange",
            icon: "i-heroicons-briefcase-20-solid"
        })
    }

    modules.push({
        label: "Equipe",
        name: "Equipe",
        description: "Gerenciar o time",
        to: "/team",
        color: "green",
        icon: "i-heroicons-user-group-20-solid"
    })

    modules.push({
        label: "Empresa",
        name: "Empresa",
        description: "Atualizar os dados da minha empresa",
        to: "/company",
        color: "green",
        icon: "i-heroicons-building-office-20-solid"
    })

    return modules
}